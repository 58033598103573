$general-color: $color-maroon;
$general-bg: rgba( $general-color, 0.15 );
$events-color: $color-grey;
$events-bg: rgba( $events-color, 0.15 );
$even-opacity: 0.15;
$odd-opacity: 0.2;
$org-1-color: $color-blue;
$org-2-color: $color-brown;
$org-3-color: $color-purple;
$org-1-even: rgba( $org-1-color, $even-opacity );
$org-1-odd: rgba( $org-1-color, $odd-opacity );
$org-2-even: rgba( $org-2-color, $even-opacity );
$org-2-odd: rgba( $org-2-color, $odd-opacity );
$org-3-even: rgba( $org-3-color, $even-opacity );
$org-3-odd: rgba( $org-3-color, $odd-opacity );

.react-calendar-timeline {
    .rct-header-root {
        #tl-controls {
            text-align: center;
            > div {
                padding: 1px 0;
            }
        }
    }
    .rct-sidebar {
        .rct-sidebar-row-even {
            &:has(> .general) {
                background-color: $general-bg;
            }
            &:has(> .events) {
                background-color: $events-bg;
            }
            &:has(> .org-1) {
                background-color: $org-1-even;
            }
            &:has(> .org-2) {
                background-color: $org-2-even;
            }
            &:has(> .org-3) {
                background-color: $org-3-even;
            }
        }
        .rct-sidebar-row-odd {
            &:has(> .general) {
                background-color: $general-bg;
            }
            &:has(> .events) {
                background-color: $events-bg;
            }
            &:has(> .org-1) {
                background-color: $org-1-odd;
            }
            &:has(> .org-2) {
                background-color: $org-2-odd;
            }
            &:has(> .org-3) {
                background-color: $org-3-odd;
            }
        }
    }
    .rct-horizontal-lines {
        .general {
            background-color: $general-bg;
        }
        .events {
            background-color: $events-bg;
        }
        .rct-hl-even {
            &.org-1 {
                background-color: $org-1-even;
            }
            &.org-2 {
                background-color: $org-2-even;
            }
            &.org-3 {
                background-color: $org-3-even;
            }
        }
        .rct-hl-odd {
            &.org-1 {
                background-color: $org-1-odd;
            }
            &.org-2 {
                background-color: $org-2-odd;
            }
            &.org-3 {
                background-color: $org-3-odd;
            }
        }
    }
    .rct-item {
        &.family {
            background-color: purple !important;
            font-weight: bold !important;
            &.birthday {
                background-color: $event-birthday-bg-color !important;
            }
        }
        &.school {
            &.holiday {
                background-color: $event-holiday-bg-color !important;
            }
            &.closure {
                font-style: italic !important;
            }
        }
        &.period {
        }
        &.absence {
            &.holiday {
                &.requested {
                    background-color: $absence-requested-bg-color !important;
                }
                &.approved {
                    background-color: $absence-approved-bg-color !important;
                }
                &.denied {
                    background-color: $absence-denied-bg-color !important;
                }
            }
            &.frozen {
                filter: grayscale(70%);
            }
        }
        .rct-item-handler {
            cursor: col-resize !important;
        }
        .rct-item-content {
            position: relative;
            width: 100%;
            .time {
                position: absolute;
                top: -22px;
                font-size: 8px;
                &.start {
                    left: 1px;
                }
                &.end {
                    right: 1px;
                }
            }
            .length {
                position: absolute;
                bottom: -21px;
                font-size: 8px;
                &.days {
                    left: 1px;
                }
            }
            .state {
                position: absolute;
                bottom: -21px;
                font-size: 8px;
                right: 1px;
            }
        }
    }
    .rct-vertical-lines {
        .rct-vl.rct-day-0, .rct-vl.rct-day-6 {
            background-color: rgba(225, 200, 150, 0.5) !important;
        }
    }
}

.role-admin, .role-guest, .member-phil, .member-alexander {
    .react-calendar-timeline {
        .rct-item {
            &.period {
                &.carer-alexander {
                    background-color: $period-color-own !important;
                }
                &.carer-victoria {
                    background-color: $period-color-other !important;
                }
            }
        }
    }
}

.member-philX, .member-victoria {
    .react-calendar-timeline {
        .rct-item {
            &.period {
                &.carer-alexander {
                    background-color: $period-color-other !important;
                }
                &.carer-victoria {
                    background-color: $period-color-own !important;
                }
            }
        }
    }
}

table.agenda {
    td {
        &[rowSpan] {
            vertical-align: top;
        }
    }
}
